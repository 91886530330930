import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { CertificatDInscription } from '../PDFs/CertificatDInscription';
import ReactPDF from '@react-pdf/renderer';
import { Document, Page, Text, View, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';

import signature from "../../images/SignatureISMI.png"
import banniere from "../../images/PictureISMI.png"

import ListGroup from 'react-bootstrap/ListGroup';




const styles = StyleSheet.create({
    page: {
        //flexDirection: 'row',
        //backgroundColor: '#E4E4E4',
        // paddingTop: 35,
        // paddingBottom: 65,
        paddingHorizontal: 25,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    text: {
        textAlign: "justify",
        margin: 12,
        textAlign: "center",
        fontSize: "13px"
    },
    header: {
        textAlign: "center",
    },
    image: {
        width: "80%",
    },
    right: {
        textAlign: "right",
        fontSize: "12px"

    },
    center: {

    },
    left: {
        textAlign: "left",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "12px"

    },
    interlignes: {
        lineHeight: "2.5em",
    },
    footer: {
        textAlign: "center",
        fontSize: "10px",
        marginTop: "50px"
    },
    signature: {
        marginTop: "50px",
        marginBottom: "50px",
        alignItems: "center"
    }
});

export const ProfilCandidat = (props) => {
    const [formationValue, setFormationValue] = React.useState("Brevet de technicien supérieur option Communication");
    const [dateDebut, setDateDebut] = React.useState("");
    const [dateFin, setDateFin] = React.useState("");

    function createNotification() {
        return NotificationManager.success('Success message', "L'email a bien été envoyé!");
    }

    const candidateInfo = React.useMemo(() => ({
        id: props.clientid,
        nom: props.nom,
        prenom: props.prenom,
        email: props.email,
        numero: props.numero
    }), [props.clientid, props.nom, props.prenom, props.email, props.numero]);

    const questionnaireResponses = React.useMemo(() => ({
        niveauFormation: props.unUn,
        domaineFormation: props.unDeux,
        modeFormation: props.unTrois,
        dernierDiplome: props.deuxUn,
        specialiteDiplome: props.specialiteDiplome,
        deuxTrois: props.deuxTrois,
        nonPresenterDiplomePourquoi: props.nonPresenterDiplomePourquoi,
        deuxQuatre: props.deuxQuatre,
        troisUn: props.troisUn,
        autreObjectif: props.autreObjectif,
        attentesEtMotivations: props.attentesEtotivations,
        motivation: props.motivation,
        depuisCombienDeTempsInteressesParLaFormation: props.depuisCombienDeTempsInteressesParLaFormation,
        quatreD: props.quatreD,
        coordonneesReferentAlternance: props.coordonneesReferentAlternance,
        quatreBisA: props.quatreBisA,
        quatreBisC: props.quatreBisC,
        qualitesPourEtreUnBonProfessionnel: props.qualitesPourEtreUnBonProfessionnel,
        cinqA: props.cinqA,
        besoinsSpecifiquesHandicap: props.besoinsSpecifiquesHandicap,
        sixA: props.sixA,
        sixB: props.sixB,
        besoinsCreationActivite: props.besoinsCreationActivite,
        autresInformationsComplementaires: props.autresInformationsComplementaires
    }), [
        props.unUn, props.unDeux, props.unTrois, props.deuxUn, props.specialiteDiplome,
        props.deuxTrois, props.nonPresenterDiplomePourquoi, props.deuxQuatre,
        props.troisUn, props.autreObjectif, props.attentesEtMotivations,
        props.motivation, props.depuisCombienDeTempsInteressesParLaFormation,
        props.quatreD, props.coordonneesReferentAlternance, props.quatreBisA,
        props.quatrebisc, props.qualitesPourEtreUnBonProfessionnel, props.cinqA,
        props.besoinsSpecifiquesHandicap, props.sixA, props.sixB,
        props.besoinsCreationActivite, props.autresInformationsComplementaires
    ]);

    const documents = React.useMemo(() => ({
        cv: process.env.REACT_APP_API_URL + props.cvMedia,
        diplome: process.env.REACT_APP_API_URL + props.diplomeMedia,
        lettre: process.env.REACT_APP_API_URL + props.lettreDeMotivationMedia,
        cni: process.env.REACT_APP_API_URL + props.cniMedia,
        signature: props.signatureUrl
    }), [props.cvMedia, props.diplomeMedia, props.lettreDeMotivationMedia, props.cniMedia, props.signatureUrl]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        emailjs.init({
            publicKey: process.env.REACT_APP_EMAILJS_PUBLICKEY,
        });
        try {

            emailjs.send("service_2dzzalg", "validation_candidature", {
                from_name: "ISMI",
                to_name: `${props.prenom} ${props.nom}`,
                recipient: `${props.email}`
            });

            alert("Votre Email de validation de candidature a bien été envoyé !")

            await axios.put(process.env.REACT_APP_API_URL + props.clientid, {
                inscrit: true,
                nomDeLaFormation: formationValue,
                dateDebutDeFormation: dateDebut,
                dateFinDeFormation: dateFin
            }).then(response => {
                window.location.href = '/dashboard';
            }).catch(error => console.error(error));
        } catch (e) {
            console.log(e)
        }
    }

    const handleSuppression = () => {
        if (window.confirm('Voulez vous supprimer ce profil ?')) {
            axios.delete(process.env.REACT_APP_API_URL + props.clientid)
                .then(response => {
                    alert("Le profil a bien été supprimé de la base de données !")
                    window.location.href = "/dashboard"
                })
        }
    }

    const handleRefus = () => {
        emailjs.init({
            publicKey: process.env.REACT_APP_EMAILJS_PUBLICKEY,
        });
        try {
            emailjs.send("service_2dzzalg", "template_04m047s", {
                from_name: "ISMI",
                to_name: `${props.prenom} ${props.nom}`,
                recipient: `${props.email}`
            });
            alert("Votre email de refus de candidature a bien été envoyé !")
        }
        catch (e) {
            //console.log(e)
        }

        if (window.confirm('Voulez vous supprimer ce candidat ?')) {
            axios.delete(process.env.REACT_APP_API_URL + props.clientid)
                .then(response => {
                    alert("Le candidat a bien été supprimé de la base de données !")
                    window.location.href = "/dashboard"
                })
        }
    }

    return (
        <div className="container">
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Valider une candidature
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="ClientId">
                                    <Form.Label>ID du candidat</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        defaultValue={candidateInfo.id}
                                    />
                                </Form.Group>
                                <Form.Group controlId="Nom">
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        defaultValue={candidateInfo.nom}
                                    />
                                </Form.Group>
                                <Form.Group controlId="Prenom">
                                    <Form.Label>Prénom</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        defaultValue={candidateInfo.prenom}
                                    />
                                </Form.Group>
                                <Form.Group controlId="Email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        defaultValue={candidateInfo.email}
                                    />
                                </Form.Group>
                                <Form.Group controlId="Numero">
                                    <Form.Label>Numéro de téléphone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        defaultValue={candidateInfo.numero}
                                    />
                                </Form.Group>
                                <Form.Group controlId="Questionnaire">
                                    <h2>Identification besoin candidat</h2>
                                    <div>
                                        <h3>1. Par quel(s) type(s) de formation(s) êtes-vous intéressé (e)?</h3>
                                        <p>Niveau de formation souhaité: {questionnaireResponses.niveauFormation}</p><br /><br />
                                        <p >Domaine de formation: {questionnaireResponses.domaineFormation}</p><br />
                                        <p>Mode de formation souhaité: {questionnaireResponses.modeFormation}</p>
                                        <h3>2. Quel est votre parcours académique et professionnel ?</h3>
                                        <p>Dernier diplôme: {questionnaireResponses.dernierDiplome}</p>
                                        <p>Spécialité dernier diplôme: {questionnaireResponses.specialiteDiplome}</p>
                                        <p>Capable de présenter diplôme: {questionnaireResponses.deuxTrois}</p>
                                        <p>Non, pourquoi: {questionnaireResponses.nonPresenterDiplomePourquoi}</p>
                                        <p>Années expérience profesionnelle: {questionnaireResponses.deuxQuatre}</p>
                                        <h3>3. Quel est votre objectif en suivant cette formation?</h3>
                                        <p >Pourquoi cette formation:    {questionnaireResponses.troisUn}</p>
                                        <p>Autre objectif de formation: {questionnaireResponses.autreObjectif}</p>
                                        <h3>4. Quelles sont vos attentes et motivations pour cette formation?</h3>
                                        <p>Attente de la formation: {questionnaireResponses.attentesEtMotivations}</p>
                                        <p>Motivation: {questionnaireResponses.motivation} </p>
                                        <p>Depuis combien de temps intéressé par la formation: {questionnaireResponses.depuisCombienDeTempsInteressesParLaFormation} </p>
                                        <p >Combien de temps à consacrer: {questionnaireResponses.quatreD}</p>
                                        <h3>4 bis. Uniquement pour les candidats souhaitant un contrat en apprentissage</h3>
                                        <p>Coordonnées référent alternance: {questionnaireResponses.coordonneesReferentAlternance} </p>
                                        <p>Avez-vous déjà une entreprise pour alternance: {questionnaireResponses.quatreBisA} </p>
                                        <p>{<p >Poste correspond-t-il a votre formation: {questionnaireResponses.quatrebisc}</p>}</p>
                                        <p>Qualité être un bon profesionnel: {questionnaireResponses.qualitesPourEtreUnBonProfessionnel} </p>
                                        <h3>5. Avez-vous des besoins spécifiques, par exemple, liés à une situation de handicap: </h3>
                                        <p>
                                            Réponse: {questionnaireResponses.cinqA}
                                        </p>
                                        <p>Lesquels: {questionnaireResponses.besoinsSpecifiquesHandicap}</p>
                                        <h3>6. Observations complémentaires</h3>
                                        <p>{<p>Compréhension formation demande 600 à 1100h: {questionnaireResponses.sixA}</p>}</p>
                                        <p>{<p >Supports numériques: {questionnaireResponses.sixB}</p>}</p>
                                        <p>Besoins pour la création d'activité: {questionnaireResponses.besoinsCreationActivite}</p>
                                        <p>Informations complémentaires: {questionnaireResponses.autresInformationsComplementaires}</p>
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="Documents">
                                    <h2>Documents à télécharger</h2>
                                    <div style={{ margin: "20px" }}>
                                        <ol>
                                            <li>
                                                <a href={documents.cv}>CV</a><br />
                                            </li>
                                            <li>
                                                <a href={documents.diplome}>Diplôme</a><br />
                                            </li>
                                            <li>
                                                <a href={documents.lettre}>Lettre de motivation</a><br />
                                            </li>
                                            <li>
                                                <a href={documents.cni}>Pièce d'identié</a><br />
                                            </li>
                                            <li>
                                                <a>Signature</a>
                                                <iframe src={documents.signature} style={{ border: 0, top: "0px", left: "0px", bottom: "0px", right: "0px", width: "100%", height: "100%" }} ></iframe><br />
                                            </li>
                                        </ol>
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Intitulé de la formation</Form.Label>
                                    <Form.Select aria-label="Default select example"
                                        onChange={e => setFormationValue(e.target.value)}>
                                        <option value="Brevet de technicien supérieur spécialité Communication en 1 an">BTS Communication en 1 an</option>
                                        <option value="Brevet de technicien supérieur spécialité Communication - Bac +1">BTS Communication - Bac +1</option>
                                        <option value="Brevet de technicien supérieur spécialité Communication - Bac +2">BTS Communication - Bac +2</option>
                                        <option value="Brevet de technicien supérieur spécialité Gestion du Transport et de la Logistique Associée en 1 an">BTS Gestion du Transport et de la Logistique Associée en 1 an</option>
                                        <option value="Brevet de technicien supérieur spécialité Gestion du Transport et de la Logistique Associée - Bac +1">BTS Gestion du Transport et de la Logistique Associée - Bac +1</option>
                                        <option value="Brevet de technicien supérieur spécialité Gestion du Transport et de la Logistique Associée - Bac +2">BTS Gestion du Transport et de la Logistique Associée - Bac +2</option>
                                        <option value="Brevet de technicien supérieur spécialité Management Commercial Opérationnel en 1 an">BTS Management Commercial Opérationnel en 1 an</option>
                                        <option value="Brevet de technicien supérieur spécialité Management Commercial Opérationnel - Bac +1">BTS Management Commercial Opérationnel - Bac +1</option>
                                        <option value="Brevet de technicien supérieur spécialité Management Commercial Opérationnel - Bac +2">BTS Management Commercial Opérationnel - Bac +2</option>
                                        <option value="Brevet de technicien supérieur spécialité Négociation et Digitalisation de la RC en 1 an">BTS Négociation et Digitalisation de la RC en 1 an</option>
                                        <option value="Brevet de technicien supérieur spécialité Négociation et Digitalisation de la RC - Bac +1">BTS Négociation et Digitalisation de la RC - Bac +1</option>
                                        <option value="Brevet de technicien supérieur spécialité Négociation et Digitalisation de la RC - Bac +2">BTS Négociation et Digitalisation de la RC - Bac +2</option>
                                        <option value="Brevet de technicien supérieur Services Informatiques option A:SISR en 1 an">BTS Services Informatiques A:SISR en 1 an</option>
                                        <option value="Brevet de technicien supérieur Services Informatiques option A:SISR - Bac +1">BTS Services Informatiques A:SISR - Bac +1</option>
                                        <option value="Brevet de technicien supérieur Services Informatiques option A:SISR - Bac +2">BTS Services Informatiques A:SISR - Bac +2</option>
                                        <option value="Brevet de technicien supérieur option Services Informatiques option B (SLAM) en 1 an">BTS Services Informatiques B SLAM en 1 an</option>
                                        <option value="Brevet de technicien supérieur option Services Informatiques option B (SLAM) - Bac +1">BTS Services Informatiques B SLAM - Bac +1</option>
                                        <option value="Brevet de technicien supérieur option Services Informatiques option B (SLAM) - Bac +2">BTS Services Informatiques B SLAM - Bac +2</option>
                                        <option value="Bac +3: Manager Commercial Opérationnel en 1 an">Bac +3: Manager Commercial Opérationnel en 1 an</option>
                                        <option value="Bac +3: Manager Commercial Opérationnel - Bac +1">Bac +3: Manager Commercial Opérationnel - Bac +1</option>
                                        <option value="Bac +3: Manager Commercial Opérationnel - Bac +2">Bac +3: Manager Commercial Opérationnel - Bac +2</option>
                                        <option value="Bac +3: Manager Commercial Opérationnel - Bac +3">Bac +3: Manager Commercial Opérationnel - Bac +3</option>
                                        <option value="Mastère de Management des Organisations en 1 an">Mastère de Management des Organisations en 1 an</option>
                                        <option value="Mastère de Management des Organisations - Bac +4">Mastère de Management des Organisations - Bac +4</option>
                                        <option value="Mastère de Management des Organisations - Bac +5">Mastère de Management des Organisations - Bac +5</option>
                                        <option value="Mastère de Management de Business Unit en 1 an">Mastère de Management de Business Unit (MBU) en 1 an</option>
                                        <option value="Mastère de Management de Business Unit - Bac +4">Mastère de Management de Business Unit (MBU) - Bac +4</option>
                                        <option value="Mastère de Management de Business Unit - Bac +5">Mastère de Management de Business Unit (MBU)- Bac +5</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group controlId="DateDebutDeFormation">
                                    <Form.Label>Date de début de formation</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={dateDebut}
                                        onChange={(e) => setDateDebut(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="DateFinDeFormation">
                                    <Form.Label>Date de fin de formation</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={dateFin}
                                        onChange={(e) => setDateFin(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <h1>Merci de vérifier le paiement des frais de formation par le candidat, avant de valider son inscription.</h1>
                                </Form.Group>
                                <Form.Group>
                                    <Button variant="primary" type="submit">
                                        Valider la candidature
                                    </Button>
                                    <NotificationContainer />
                                </Form.Group>
                                <Form.Group>
                                    <Button variant="success" onClick={handleSuppression}>
                                        Supprimer le profil
                                    </Button>
                                </Form.Group>
                                <Form.Group>
                                    <Button variant="danger" onClick={() => handleRefus()}>
                                        Refuser la candidature
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer style={{ marginTop: "30px" }}>
                    <Button variant="danger" onClick={props.onHide}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
